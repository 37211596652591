import Vue      from 'vue'
import Vuex     from 'vuex'
import magamApi from '@/api/v1/magams'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const state = {
  magams: [],
  magamPerformances: [],
  timesheetsMagamPerformances: [],
}

const getters = {
  getField,
}

const actions = {
  getMagams ({ commit, dispatch }, params) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      magamApi.getMagams(params).then(resp => {
        commit('setMagams', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  getMagamPerformances ({ commit, dispatch }, params) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      magamApi.getMagamPerformances(params).then(resp => {
        commit('setMagamPerformances', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  createMagamPerformances ({ dispatch }, params) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      magamApi.createMagamPerformances(params).then(resp => {
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  createChangePerformances ({ dispatch }, params) {
    console.log('createChangePerformances', params)
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      magamApi.createChangePerformances(params).then(resp => {
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  deleteInvoiceChangePerformances ({ dispatch }, params) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      magamApi.deleteInvoiceChangePerformances(params).then(resp => {
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  deleteDepositChangePerformances ({ dispatch }, depositId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      magamApi.deleteDepositChangePerformances({deposit_id: depositId}).then(resp => {
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  getTimesheetsMagamPerformances ({ commit, dispatch }, params) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      magamApi.getTimesheetsMagamPerformances(params).then(resp => {
        commit('setTimesheetsMagamPerformances', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
}

const mutations = {
  setMagams (state, fromApi) {
    state.magams = fromApi
  },
  setMagamPerformances (state, fromApi) {
    state.magamPerformances = fromApi
  },
  setTimesheetsMagamPerformances (state, fromApi) {
    state.timesheetsMagamPerformances = fromApi
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
